import  rfpStatusTypeConst  from '../constantes/rfpStatusTypeConst';
import { rfp } from "../interfaces/nostromo/service/generic/rfp";
import { lockRfpRequest } from "../interfaces/nostromo/service/lockRfpRequest";
import { setRfpsRequest } from "../interfaces/nostromo/service/setRfpsRequest";
import { getRfpBudgetReportResponse } from '../interfaces/nostromo/service/getRfpBudgetReportResponse';
import { getRfpRequest } from "../interfaces/nostromo/service/getRfpRequest";
import { getRfpsRequest } from '../interfaces/nostromo/service/getRfpsRequest';
import { rejectedRfp } from '../interfaces/nostromo/service/massRfpRejectionResponse';
import { changeRFPStatusRequest } from '../interfaces/nostromo/service/changeRFPStatusRequest';
import { setRfpContactEmailRequest } from '../interfaces/nostromo/service/setRfpContactEmailRequest';
import { getGdprRequest } from '../interfaces/nostromo/service/getGdprRequest';
import { changeRFPStatusResponse } from '../interfaces/nostromo/service/changeRFPStatusResponse';
import { getGdprResponse } from '../interfaces/nostromo/service/getGdprResponse';
import { getRfpCompanyPricesRequest } from '../interfaces/nostromo/service/getRfpCompanyPricesRequest';
import { synctRfpToCRMRequest }  from '../interfaces/nostromo/service/synctRfpToCRMRequest';
import {responseBase }  from '../interfaces/nostromo/service/generic/responseBase';
import { setRfpAdditionalInformationRequest } from '../interfaces/nostromo/service/setRfpAdditionalInformationRequest';
import { getRfpCompanyPriceNotesRequest } from '../interfaces/nostromo/service/getRfpCompanyPriceNotesRequest';
import { getRfpCompanyPriceNotesResponse } from '../interfaces/nostromo/service/getRfpCompanyPriceNotesResponse';
import { setRfpCompanyPricesRequest } from '../interfaces/nostromo/service/setRfpCompanyPricesRequest';
import { rfpCompanyPriceModel } from '../modelos/precios/rfpCompanyPriceModel';
import { getRfpDocumentsRequest } from '../interfaces/nostromo/service/getRfpDocumentsRequest';
import { getRfpDocumentsResponse } from '../interfaces/nostromo/service/getRfpDocumentsResponse';
import { setRfpCompaniesRequest } from '../interfaces/nostromo/service/setRfpCompaniesRequest'
import { setRfpCompanyDocumentsRequest } from '../interfaces/nostromo/service/setRfpCompanyDocumentsRequest';
import { setRfpDocumentsRequest } from '../interfaces/nostromo/service/setRfpDocumentsRequest';
import { getRfpCompanyDocumentsRequest } from '../interfaces/nostromo/service/getRfpCompanyDocumentsRequest';
import { getRfpCompanyDocumentsResponse } from '../interfaces/nostromo/service/getRfpCompanyDocumentsResponse';
import { changeRfpEffectiveDateRequest } from '../interfaces/nostromo/service/changeRfpEffectiveDateRequest';
import { changeRfpEffectiveDateResponse } from '../interfaces/nostromo/service/changeRfpEffectiveDateResponse';
import { getCompanyIssuanceEmailsRequest } from '../interfaces/nostromo/service/getCompanyIssuanceEmailsRequest';
import { getRfpCompanyTuningsRequest } from '../interfaces/nostromo/service/getRfpCompanyTuningsRequest';
import { getRfpCompanyTuningsResponse } from '../interfaces/nostromo/service/getRfpCompanyTuningsResponse';
import { ajustRfpCompanyPriceRequest } from '../interfaces/nostromo/service/ajustRfpCompanyPriceRequest';
import { getRecoverRfpDocumentsRequest } from '../interfaces/nostromo/service/getRecoverRfpDocumentsRequest';
import { recoverRfpDocumentsRequest } from '../interfaces/nostromo/service/recoverRfpDocumentsRequest';
import { getRfpsInitialDataResponse } from '../interfaces/nostromo/service/getRfpsInitialDataResponse';
import { getRfpsInitialDataRequest } from '../interfaces/nostromo/service/getRfpsInitialDataRequest';
import { getRfpAnswerRequest } from '../interfaces/nostromo/service/getRfpAnswerRequest';
import { getRfpAnswerResponse } from '../interfaces/nostromo/service/getRfpAnswerResponse';
import { getRfpUsersRequest } from '../interfaces/nostromo/service/getRfpUsersRequest';
import { getRfpUsersResponse } from '../interfaces/nostromo/service/getRfpUsersResponse';
import { setRfpUsersRequest } from '../interfaces/nostromo/service/setRfpUsersRequest';

import { getRfpCompaniesRequest } from '../interfaces/nostromo/service/getRfpCompaniesRequest';
import { getRfpCompaniesResponse } from '../interfaces/nostromo/service/getRfpCompaniesResponse';
import { getRfpCompanyLogRequest } from '../interfaces/nostromo/service/getRfpCompanyLogRequest';
import { getRfpCompanyLogResponse } from '../interfaces/nostromo/service/getRfpCompanyLogResponse';

import { confirmPriceRequest } from '../interfaces/nostromo/service/confirmPricesRequest';
import { confirmPriceResponse } from '../interfaces/nostromo/service/confirmPriceResponse';

import { getRfpPoliciesRequest } from '../interfaces/nostromo/service/getRfpPoliciesRequest';
import { getIssuanceCompanyQuestionsRequest } from '../interfaces/nostromo/service/getIssuanceCompanyQuestionsRequest';
import { getCustomSupplementvaluesForReceiptRequest } from '../interfaces/nostromo/service/getCustomSupplementvaluesForReceiptRequest';
import { getCustomSupplementvaluesForReceiptResponse } from '../interfaces/nostromo/service/getCustomSupplementvaluesForReceiptResponse';


import { getRfps0InitialDataRequest } from '../interfaces/nostromo/service/getRfps0InitialDataRequest';
import { getRfps0InitialDataResponse } from '../interfaces/nostromo/service/getRfps0InitialDataResponse';


import { getRfps5InitialDataRequest } from '../interfaces/nostromo/service/getRfps5InitialDataRequest';
import { getRfps5InitialDataResponse } from '../interfaces/nostromo/service/getRfps5InitialDataResponse';


import { getRfpsEntityInitialDataRequest } from '../interfaces/nostromo/service/getRfpsEntityInitialDataRequest';
import { getRfpsEntityInitialDataResponse } from '../interfaces/nostromo/service/getRfpsEntityInitialDataResponse';


import { getRfpsComplementaryInitialDataRequest } from '../interfaces/nostromo/service/getRfpsComplementaryInitialDataRequest';
import { getRfpsComplementaryInitialDataResponse } from '../interfaces/nostromo/service/getRfpsComplementaryInitialDataResponse';

import { setRfpCompanyPricesLinkedsRequest } from '../interfaces/nostromo/service/setRfpCompanyPricesLinkedsRequest';
import { rejectPolicyIssuanceRequest } from '../interfaces/nostromo/service/rejectPolicyIssuanceRequest';
import { forcePolicyIssuanceByEmailRequest } from '../interfaces/nostromo/service/forcePolicyIssuanceByEmailRequest';

import { gestionDeCompentenciasRequest } from '../interfaces/nostromo/service/gestionDeCompentenciasRequest';

import ApiNostromo from "../servicios/ApiNostromo";
import HelperSecurity from "./HelperSecurity";
import HelperUtils from './HelperUtils';
import MessageService, { MessageType } from "../servicios/MessageService";
import { rfpPolicy } from "../interfaces/nostromo/service/generic/rfpPolicy";
import { issuanceCompanyQuestion } from "../interfaces/nostromo/service/generic/issuanceCompanyQuestion";
import { rfpCompanyPrice } from "../interfaces/nostromo/service/generic/rfpCompanyPrice";
import DirectiveConst from '../constantes/DirectiveConst';
import HelperDirectives from './HelperDirectives';
import { rfpStatusType } from '../interfaces/nostromo/service/generic/enums/rfpStatusType';
// import { getRfpStatusRequest, getRfpStatusResponse, rfpStatus } from 'src/interfaces';
import { getRfpStatusRequest, requestBase, rfpStatus } from '../interfaces';
import { setRfpCompanyPriceCoverageResponse } from '../interfaces/nostromo/service/setRfpCompanyPriceCoverageResponse';
import { setRfpCompanyPriceCoverageRequest } from '../interfaces/nostromo/service/setRfpCompanyPriceCoverageRequest';
import { rfpCompanyPriceCoverage } from "../interfaces/nostromo/service/generic/rfpCompanyPriceCoverage";
import { setRfpIssuanceCertificationRequest  } from '../interfaces/nostromo/service/setRfpIssuanceCertificationRequest';



export const ACTIONS = "accion";
export const CONSULTAR = "see";
export const RECHAZAR = "reject";
export const DETALLES = "detalle";
export const DOCUMENTOS = "docs";
export const DESBLOQUEAR = "unlock";
export const DUPLICAR = "duplicate";
export const ELIMINAR = "delete";
export const RECHAZARMASIVO = "rejectmass";


export enum lockedActions {
    unlock,
    all,
    none
}

const DataStatusMap = [
    {
        statusId: rfpStatusTypeConst.pte_solicitar_cia,
        RfpStatusData: {
            Redirect: "~/rfps",
            Component:"solicitud0",
            InfoStatus: {
                ButtonTitle: "Editar", ButtonTooltip: "Solicitar cotización a la compañía", ButtonIcon: "fa fa-cloud-upload", ColorCss: "bg-pte_solicitar_cia",
                Color: "#4b8df8",
            }
        }
    },
    {
        statusId: rfpStatusTypeConst.pte_solicitar_service,
        RfpStatusData: {
            Redirect: "~/rfps",
            Component:"solicitud0",
            InfoStatus: {
                ButtonTitle: "Editar", ButtonTooltip: "Solicitar cotización a la compañía", ButtonIcon: "fa fa-cloud-upload", ColorCss: "bg-pte_solicitar_cia",
                Color: "#4b8df8",
            }
        }
    },
    {
        statusId: rfpStatusTypeConst.pte_respuesta_cia,
        RfpStatusData: {
            Redirect: "~/rfps",
            Component:"solicitud0",

            InfoStatus: {
                ButtonTitle: "Editar", ButtonTooltip: "Anotar las cotizaciones recibidas", ButtonIcon: "fa fa-pencil-square", ColorCss: "bg-pte_respuesta_cia"
                ,Color: "#f2784b",
            }
        }
    },

    {
        statusId: rfpStatusTypeConst.pte_presentar_cliente,
        RfpStatusData: {
            Redirect: "~/rfps",
            Component:"solicitud0",

            InfoStatus: {
                ButtonTitle: "Editar", ButtonTooltip: "Presentar presupuesto al cliente", ButtonIcon: "fa fa-folder-open", ColorCss: "bg-pte_presentar_cliente"
                ,Color: "#4b77be",
            }
        }
    },

    {
        statusId: rfpStatusTypeConst.pte_respuesta_cliente,
        RfpStatusData: {
            Redirect: "~/rfps",
            Component:"solicitud0",
            InfoStatus: {
                ButtonTitle: "Editar",ButtonTooltip: "Anotar la respuesta del cliente", ButtonIcon: "fa fa-pencil-square", ColorCss: "bg-pte_respuesta_cliente"
                ,Color: "#ffb848",
            }
        }
    },


    {
        statusId: rfpStatusTypeConst.hibernada,
        RfpStatusData: {
            Redirect: "~/rfps",
            Component:"solicitud0",
            InfoStatus: {
                ButtonTitle: "Reiniciar", ButtonTooltip: "Consultar  detalle de la solicitud", ButtonIcon: "fa fa-history", ColorCss: "bg-hibernada"
                ,Color: "#555555",
            }
        }
    },
    {
        statusId: rfpStatusTypeConst.pte_emision,
        RfpStatusData: {
            Redirect: "~/rfps",
            Component:"solicitud5",
            InfoStatus: {
                ButtonTitle: "Editar", ButtonTooltip: "Solicitar emisión a la compañía", ButtonIcon: "fa fa-cloud-upload", ColorCss: "bg-pte_emision"
                ,Color: "#1ba39c",
            }
        }
    },
    {
        statusId: rfpStatusTypeConst.rechazado_cliente,
        RfpStatusData: {
            Redirect: "~/rfps",
            Component:"solicitud7",
            InfoStatus: {
                ButtonTitle: "Consultar", ButtonTooltip: "Consultar detalle de la solicitud", ButtonIcon: "fa fa-list-alt", ColorCss: "bg-rechazado_cliente"
                ,Color: "#ef4836",
            }
        }
    },

    {
        statusId: rfpStatusTypeConst.pte_respuesta_emision,
        RfpStatusData: {
            Redirect: "~/rfps",
            Component:"solicitud6",
            InfoStatus: {
                ButtonTitle: "Editar", ButtonTooltip: "Anotar la respuesta de emisión", ButtonIcon: "fa fa-pencil-square", ColorCss: "bg-pte_respuesta_emision"
                ,Color: "#e87e04",
            }
        }
    },
    {
        statusId: rfpStatusTypeConst.poliza_emitida,
        RfpStatusData: {
            Redirect: "~/rfps",
            Component:"solicitud6",
            InfoStatus: {
                ButtonTitle: "Editar", ButtonTooltip: "Consultar detalle de la póliza", ButtonIcon: "fa fa-list-alt", ColorCss: "bg-poliza_emitida"
                ,Color: "#26c281",
            }
        }
    },
    {
        statusId: rfpStatusTypeConst.poliza_rechazada,
        RfpStatusData: {
            Redirect: "~/rfps",
            Component:"solicitud6",
            InfoStatus: {
                ButtonTitle: "Consultar", ButtonTooltip: "Consultar detalle de la póliza", ButtonIcon: "fa fa-list-alt", ColorCss: "bg-poliza_rechazada"
                ,Color: "#ef4836",
            }
        }
    },

    {
        statusId: rfpStatusTypeConst.pte_tarificar,
        RfpStatusData: {
            Redirect: "~/rfps",
            Component:"tarificador",
            InfoStatus: {
                ButtonTitle: "Editar", ButtonTooltip: "Rellenar solicitud de cotización", ButtonIcon: "fa fa-pencil-square", ColorCss: "bg-pte_tarificar"
                ,Color: "#9a12b3",
            }
        }
    },
];



const canEdit = (user,solicitud) => {
    if (user && solicitud) {
        const allowedLockedActions = rfpActionsEnabled(user, solicitud);
        let unlockVisible = (allowedLockedActions == lockedActions.none || allowedLockedActions == lockedActions.unlock);
        const lockedBySameUser = (solicitud.lockedByUser != null && solicitud.lockedByUser.id == user.id);
        if (lockedBySameUser){
            unlockVisible = false;
        }
        const statusId = (solicitud.status?.id ?? '');
        const directiveEdit = HelperDirectives.validateDirective(user, statusId + DirectiveConst.EDIT);
        return !unlockVisible && directiveEdit;
    }

    return false;

}

// Obtiene la informacion de los estados.
const ObtainDataStatus = (statusId) => {
    const data = DataStatusMap.filter(x => x.statusId == statusId);

    if (data.length > 0) {
        return data[0].RfpStatusData.InfoStatus;
    }
    else {
        return {
            ButtonTitle: "Acción",
            ColorCss: "bg-pte_presentar_cliente",
            ButtonTooltip: "",
            ButtonIcon: "bg-principal",
            Color: "#4b77be",
        }
    }
};


const isSolicitud0 = (solicitud): boolean => {
    if (solicitud) {
        return DataStatusMap.findIndex(x => x.statusId == solicitud.status?.id  && x.RfpStatusData.Component == 'solicitud0') != -1
    }

    return false;

};

const isSolicitud5 = (solicitud): boolean => {
    if (solicitud) {
        return DataStatusMap.findIndex(x => x.statusId == solicitud.status?.id  && x.RfpStatusData.Component == 'solicitud5') != -1
    }

    return false;

};

const isSolicitud6 = (solicitud): boolean => {
    if (solicitud) {
        return DataStatusMap.findIndex(x => x.statusId == solicitud.status?.id && x.RfpStatusData.Component == 'solicitud6') != -1
    }

    return false;

};

const isSolicitud7 = (solicitud): boolean => {
    if (solicitud) {
        return DataStatusMap.findIndex(x => x.statusId == solicitud.status?.id  && x.RfpStatusData.Component == 'solicitud7') != -1
    }

    return false;

};


const rfpActionsEnabled = (user, rfp): lockedActions => {
    try {
        if (rfp.locked && rfp.lockedByUser.id == user.id) {
            return lockedActions.all;
        }
        // si esta bloqueada y no es el usuario que la tiene bloqueada, validamos si tiene la directiva para desbloquearla
        else {
            if (rfp.locked && HelperSecurity.validateDirective(user, DirectiveConst.RFP_UNLOCK)) {
                return lockedActions.unlock;
            }
        }
        return lockedActions.none;
    }
    catch (exception) {
        return lockedActions.none;
    }
}


const GetRfp = (sessionID, rfpId,withPrices = false,withRiskDescription=false,withStatusLog = false,withRfpTasks = false,withProductCompanies=false,defaultUser=false,withAnswers=false,withCompanyQuestionnaire=false,rfpVersion=null): Promise<rfp> => {
    return new Promise<rfp>((resolve, reject) => {
        if (sessionID == '') {
            reject();
        }
        else {
            const request: Partial<getRfpRequest> = ({
                all: false,
                id: rfpId,
                version:rfpVersion,
                sessionID: sessionID,
                withPrices:withPrices,
                withRiskDescription:withRiskDescription,
                withStatusLog: withStatusLog,
                withRfpTasks: withRfpTasks,
                withProductCompanies:withProductCompanies,
                automaticFromWebAndDefaultUser:defaultUser,  
                withAnswers:withAnswers,
                withCompanyQuestionnaire:withCompanyQuestionnaire            

            });            
            ApiNostromo.post('/rfp/getRfp', request as any)
                .then((response) => {
                    resolve(response.data.rfps[0]);
                })
                .catch(() => {
                    reject();
                });
        }
    });
}
const getRfpVersions = (sessionID, rfpId): Promise<rfp[]> => {
    return new Promise<rfp[]>((resolve, reject) => {
        if (sessionID == '') {
            reject();
        }
        else {
            const request: Partial<getRfpRequest> = ({
                all: false,
                id: rfpId,
                sessionID: sessionID,
            });

            ApiNostromo.post('/rfp/getRfpVersions', request as any)
                .then((response) => {
                    resolve(response.data.rfps);
                })
                .catch(() => {
                    reject();
                });
        }
    });
}

const LockRfp = (sessionID, user, rfpId, rfp,rfpList) => {
    return new Promise<rfp | null>((resolve, reject) => {
        if (sessionID == '') {
            resolve(null);
        }
        else {
            if (rfp == null) {
                GetRfp(sessionID, rfpId, undefined, true).then((data) => {
                    rfp = data as rfp;//[0] as any;                
                }).then(() => {
                    LockRfpDatabase(sessionID, user, rfpId, rfpList).then(() => {
                        UpdateLockRfps([rfp], rfpId, true, user);
                        resolve(rfp);
                    });
                })
                    .catch(() => {
                        reject();
                    });;
            }
            else {
                // Actualizamos el rfp
                LockRfpDatabase(sessionID, user, rfpId, rfpList).then((resp) => {
                    if (resp){
                        UpdateLockRfps([rfp], rfpId, true, user);
                        resolve(rfp);    
                    }
                    else{
                        resolve(null);    
                    }
                });
            }
        }
    });
};


const UnlockRfp = (sessionID, user, rfpId, rfpList): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {
        if (sessionID == '') {
            resolve(false);
        }
        else {
            const request: lockRfpRequest = {
                sessionID: sessionID,
                rfpId: rfpId,
                userId: user.id,
                noLockPolicyIssue:true
            };
            ApiNostromo.post('/rfp/unlockRfp', request as any)
                .then(() => {
                    if (rfpList) {
                        UpdateLockRfps(rfpList, rfpId);
                    }

                    resolve(true);
                })
                .catch(() => {
                    reject();
                });
        }
    });
}

const LockRfpDatabase = (sessionID, user, rfpId, rfpList): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {
        if (sessionID == '') {
            resolve(false);
        }
        else {
            const request: lockRfpRequest = {
                sessionID: sessionID,
                rfpId: rfpId,
                userId: user.id,
                noLockPolicyIssue:true
            };
            ApiNostromo.post('/rfp/lockRfp', request as any)
                .then(() => {
                    if (rfpList) {
                        UpdateLockRfps(rfpList, rfpId, true, user);
                    }

                    resolve(true);
                })
                .catch(() => {
                    reject();
                });
        }
    });
}

const DeleteRfp = (sessionID, rfpId, rfpsList): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {
        if (sessionID == '') {
            resolve(false);
        }
        else {
            const data: Partial<rfp> = {
                id: rfpId
            };
            const request: Partial<setRfpsRequest> = {
                sessionID: sessionID,
                rfps: [data],
                calledFromIntegrationService: false
            };
            ApiNostromo.post('/rfp/deleteRFPs', request as any)
                .then(() => {
                    if (rfpsList) {
                        rfpsList = HelperUtils.RemoveObjectById(rfpId, rfpsList);
                    }

                    resolve(true);
                })
                .catch(() => {
                    reject();
                });
        }
    });
}
const rollbackRFPStatus = (sessionID, rfpId,newStatusId, rfpsList,hibernateDate=null,hibernateCause=null,prices=null): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {
        if (sessionID == '') {
            resolve(false);
        }
        else {
            const data: Partial<rfp> = {
                id: rfpId
            };
            const request: Partial<changeRFPStatusRequest> = {
                sessionID: sessionID,
                rfp: data,
                newStatus:newStatusId,
                hibernateCause:hibernateCause as any,
                date:hibernateDate,
                prices:prices as any

            };
            ApiNostromo.post('/rfp/rollbackRFPStatus', request as any)
                .then((response) => {
                    
                    if (rfpsList) {
                        rfpsList = UpdateStateRfps(response.data,rfpId, rfpsList);
                    }

                    resolve(true);
                })
                .catch(() => {
                    reject();
                });
        }
    });
}


const getChangeRFPStatusRequest = (sessionId,rfpcompanypriceId, newStatus, data = null, HibernateCauseOrRejectDescription = "", rfpCompany = null) => {
    const changeStatusRequest: Partial<changeRFPStatusRequest> = (
        {
            sessionID: sessionId,
            rfp: {
                id: rfpcompanypriceId,
                statusReason: (newStatus == rfpStatusType.rechazado_cliente ? {
                    id: data == null ? "" : data,
                    description: HibernateCauseOrRejectDescription,
                } :
                    {
                        id: "",
                        description: "",
                    }) as any,
                companies: (newStatus == rfpStatusType.pte_respuesta_cia ? [rfpCompany] : []) as any,

            },
            newStatus: newStatus,
            prices: (newStatus == rfpStatusType.pte_emision || newStatus == rfpStatusType.pte_respuesta_emision ? (data as any ): []),
            date: (newStatus == rfpStatusType.hibernada ? data : null),
            hibernateCause: (newStatus == rfpStatusType.hibernada ? HibernateCauseOrRejectDescription : ''),
        });


    return changeStatusRequest;
}

const setRfpsReference = (setRfpsRequest): Promise<responseBase> => {
    return new Promise<responseBase>((resolve, reject) => {
        ApiNostromo.post('/rfp/setRfpsReference', setRfpsRequest as any)
            .then((response) => {
                resolve(response.data);
            }).catch(() => {
                reject();
            });
    });
}

const setRfpsObservation = (setRfpsRequest): Promise<responseBase> => {
    return new Promise<responseBase>((resolve, reject) => {
        ApiNostromo.post('/rfp/setRfpsObservation', setRfpsRequest as any)
            .then((response) => {
                resolve(response.data);
            }).catch(() => {
                reject();
            });
    });
}

const getRfpBudgetReport = (getRfpBudgetReportRequest): Promise<getRfpBudgetReportResponse> => {
    return new Promise<getRfpBudgetReportResponse>((resolve, reject) => {
        ApiNostromo.post('/rfp/getRfpBudgetReport', getRfpBudgetReportRequest as any)
            .then((response) => {
                resolve(response.data);
            }).catch(() => {
                reject();
            });
    });
}

const getCoverageComparativeReport = (getCoverageComparativeRequest): Promise<getRfpBudgetReportResponse> => {
    return new Promise<getRfpBudgetReportResponse>((resolve, reject) => {
        ApiNostromo.post('/rfp/getCoverageComparativeReport', getCoverageComparativeRequest as any)
            .then((response) => {
                resolve(response.data);
            }).catch(() => {
                reject();
            });
    });
}

const changeRFPStatus = (request:changeRFPStatusRequest): Promise<changeRFPStatusResponse> => {
    return new Promise<changeRFPStatusResponse>((resolve, reject) => {
        ApiNostromo.post('/rfp/changeRFPStatus', request as any)
            .then((response) => {
                resolve(response.data);
            }).catch(() => {
                reject();
            });
    });
}

const setRfpContactEmail = (request:setRfpContactEmailRequest): Promise<responseBase> => {
    return new Promise<responseBase>((resolve, reject) => {
        ApiNostromo.post('/rfp/setRfpContactEmail', request as any)
            .then((response) => {
                resolve(response.data);
            }).catch(() => {
                reject();
            });
    });
}

const getGDPR = (request:getGdprRequest): Promise<getGdprResponse> => {
    return new Promise<getGdprResponse>((resolve, reject) => {
        ApiNostromo.post('/rfp/getGDPR', request as any)
            .then((response) => {
                resolve(response.data);
            }).catch(() => {
                reject();
            });
    });
}
 
const sendGDPRRequest = (request:getGdprRequest): Promise<responseBase> => {
    return new Promise<responseBase>((resolve, reject) => {
        ApiNostromo.post('/rfp/sendGDPRRequest', request as any)
            .then((response) => {
                resolve(response.data);
            }).catch(() => {
                reject();
            });
    });
}

const checkLock = (user, rfp): boolean => {
        if (rfp.locked && rfp.lockedByUser.id != user.id) {
        const lockedMsg = "La solicitud se encuentra bloqueada por el usuario " + (rfp.lockedByUser.email ?? '') + " (" + rfp.lockedByUser.name + ")";
        MessageService.showMessage(MessageType.Aviso, '', lockedMsg);
        return true;
    }

    return false;

};
const UpdateStateRfps = (changeStatusResponse,rfpId,rfpsList) => {
    rfpsList.filter(x => x.id == rfpId).forEach(rfp => {
        rfp.status.id = changeStatusResponse.changedStatus.id;
        rfp.status.description = changeStatusResponse.changedStatus.description;    
    });
}


const UpdateLockRfps = (rfpsList, rfpId, locked = false, lockByUser = null) => {
    rfpsList.filter(x => x.id == rfpId).forEach(rfp => {
        rfp.locked = locked;
        rfp.lockedByUser = lockByUser;
    });
}


function getRfpCompanyPrices(sessionID, rfpId, noLogs = true): Promise<rfpCompanyPriceModel[] | null> {
    return new Promise<rfpCompanyPriceModel[] | null>((resolve, reject) => {
        if (sessionID == '') {
            resolve(null);
        }
        else {
            const request: Partial<getRfpCompanyPricesRequest> = {
                sessionID: sessionID,
                rfpId: rfpId,
                noLogs: noLogs
            };
            ApiNostromo.post('/rfp/getRfpCompanyPrices', request as any)
                .then((response) => {


                    resolve(response.data.rfpCompanyPrices ?? []);
                })
                .catch(() => {
                    reject();
                });
        }
    });
}
const SendRfpToBrokerManager = (sessionId,rfpId): Promise<responseBase> => {
    return new Promise<responseBase>((resolve, reject) => {
        const request: Partial<synctRfpToCRMRequest>={
            sessionID: sessionId,
            rfpId:rfpId,
        };
        ApiNostromo.post('/rfp/sendRfpToBrokerManager', request as any)
            .then((response) => {
                resolve(response.data);
            }).catch(() => {
                reject();
            });
        
    });
}
const massRfpRejection = (request: getRfpsRequest): Promise<rejectedRfp[] | null> => {
    return new Promise<rejectedRfp[] | null>((resolve, reject) => {
        if (request.sessionID == '') {
            resolve(null);
        } else {
            ApiNostromo.post('/rfp/massRfpRejection', request as any)
                .then((response) => {
                    resolve(response.data.rfps ?? []);
                })
                .catch(() => {
                    reject();
                });
        }
    });
}
const SetRfpAdditionalInformation = (request:setRfpAdditionalInformationRequest ): Promise<responseBase> => {
    return new Promise<responseBase>((resolve, reject) => {
        ApiNostromo.post('/rfp/setRfpAdditionalInformation', request as any)
            .then((response) => {
                resolve(response.data);
            }).catch(() => {
                reject();
            });
    
    });
}
const setRfpNoticeDate = (request:setRfpAdditionalInformationRequest ): Promise<responseBase> => {
    return new Promise<responseBase>((resolve, reject) => {
        ApiNostromo.post('/rfp/setRfpNoticeDate', request as any)
            .then((response) => {
                resolve(response.data);
            }).catch(() => {
                reject();
            });
    
    });
}
const getRfps = (request: Partial<getRfpsRequest>): Promise<[rfp[] | null,number]> => {
    return new Promise<[rfp[] | null,number]>((resolve, reject) => {
        if (request == null) {
            resolve([null,0]);
        }
        else {
            ApiNostromo.post('/rfp/getRfps', request as any)
            .then((response) => {
                resolve([response.data.rfps ?? [],response.data.recordsLimit]);
                
            })
            .catch(() => {
                reject();
            });
        }
    });
}

const getRfpCurrentStatus = (request): Promise<rfp> => {
    return new Promise<rfp>((resolve, reject) => {
            ApiNostromo.post('/rfp/getRfpCurrentStatus', request as any)
            .then((response) => {
                resolve(response.data.rfps[0]);
                
            })
            .catch(() => {
                reject();
            });
    });
}

const getRfpCompanyPriceNotes = (sessionId:string,priceId:string) : Promise<getRfpCompanyPriceNotesResponse> => {
    return new Promise<getRfpCompanyPriceNotesResponse>((resolve,reject)=>{
        const request: Partial<getRfpCompanyPriceNotesRequest>={
            sessionID: sessionId,
            priceId:priceId,
        };
        ApiNostromo.post('/rfp/getRfpCompanyPriceNotes', request as any)
        .then((response) => {
            resolve(response.data);
            
        })
        .catch(() => {
            reject();
        });
    });
}

const getRfpDocuments = (request:getRfpDocumentsRequest ): Promise<getRfpDocumentsResponse> => {
    return new Promise<getRfpDocumentsResponse>((resolve, reject) => {
        ApiNostromo.post('/rfp/getRfpDocuments', request as any)
            .then((response) => {
                resolve(response.data);
            }).catch(() => {
                reject();
            });
    
    });
}
const setRfpCompanyPrice = (sessionId:string,prices:rfpCompanyPriceModel[],fromRfp0=true) : Promise<responseBase> => {
    return new Promise<responseBase>((resolve, reject)=>{
        const request: Partial<setRfpCompanyPricesRequest>={
            sessionID: sessionId,
            fromRfps00:  fromRfp0,
            rfpCompanyPrices:  prices,            
        };
        ApiNostromo.post('/rfp/setRfpCompanyPrices', request as any)
        .then((response) => {
            resolve(response.data);
        }).catch(() => {
            reject();
        });

    });
}

const setRfpCompanyPricePodium = (sessionId:string,prices:rfpCompanyPriceModel[],fromRfp0=true) : Promise<responseBase> => {
    return new Promise<responseBase>((resolve, reject)=>{
        const request: Partial<setRfpCompanyPricesRequest>={
            sessionID: sessionId,
            fromRfps00:  fromRfp0,
            rfpCompanyPrices:  prices,            
        };
        ApiNostromo.post('/rfp/setRfpCompanyPricesPodium', request as any)
        .then((response) => {
            resolve(response.data);
        }).catch(() => {
            reject();
        });

    });
}

const getRfpCompanyDocuments = (request:Partial<getRfpCompanyDocumentsRequest>): Promise<getRfpCompanyDocumentsResponse> => {
    return new Promise<getRfpCompanyDocumentsResponse>((resolve, reject) => {

        ApiNostromo.post('/rfp/getRfpCompanyDocuments', request as any)
            .then((response) => {
                resolve(response.data);
            }).catch(() => {
                reject();
            });    
    });
}


const setRfpDocuments = (request:setRfpDocumentsRequest ): Promise<responseBase> => {
    return new Promise<responseBase>((resolve, reject) => {
        ApiNostromo.post('/rfp/setRfpDocuments', request as any)
            .then((response) => {
                resolve(response.data);
            }).catch(() => {
                reject();
            });
    
    });
}


const setRfpCompanyDocuments = (request:Partial<setRfpCompanyDocumentsRequest>): Promise<responseBase> => {
    return new Promise<responseBase>((resolve, reject) => {

        ApiNostromo.post('/rfp/setRfpCompanyDocuments', request as any)
            .then((response) => {
                resolve(response.data);
            }).catch(() => {
                reject();
            });    
    });
}



const  setRfpCompanies = ( request:setRfpCompaniesRequest): Promise<responseBase> => {
    return new Promise<responseBase>((resolve, reject) => {
        ApiNostromo.post('/rfp/setRfpCompanies', request as any)
            .then((response) => {
                resolve(response.data);
            })
            .catch(() => {
                reject();
            });
    });
}

const  getRfpCompanies = ( request:getRfpCompaniesRequest): Promise<getRfpCompaniesResponse> => {
    return new Promise<getRfpCompaniesResponse>((resolve, reject) => {
        ApiNostromo.post('/rfp/getRfpCompanies', request as any)
            .then((response) => {
                resolve(response.data);
            })
            .catch(() => {
                reject();
            });
    });
}
const  getRfpCompanyLog = ( request:getRfpCompanyLogRequest): Promise<getRfpCompanyLogResponse> => {
    return new Promise<getRfpCompanyLogResponse>((resolve, reject) => {
        ApiNostromo.post('/rfp/getRfpCompanyLog', request as any)
            .then((response) => {
                resolve(response.data);
            })
            .catch(() => {
                reject();
            });
        })
    }

const confirmRfpCompanyPrice = (request:Partial<confirmPriceRequest>) : Promise<confirmPriceResponse> => {
    return new Promise<confirmPriceResponse>((resolve, reject)=>{       
        ApiNostromo.post('/rfp/confirmRfpCompanyPrice', request as any)
        .then((response) => {
            resolve(response.data);
        }).catch(() => {
            reject();
        });


    });

}

  // Comprueba si el usuario tiene permisos para actuar sobre un determinado estado
  const checkEditRFPStatus = (user,statusId) =>
  {
      try
      {
          const status = statusId + "Edit";
          
          if (HelperSecurity.validateDirective(user,status))
          {
              return true;
          }

          return false;
      }
      catch (Exception)
      {
          return false;
      }
  } 
  
  const getRfpPolicies = (request:Partial<getRfpPoliciesRequest>) : Promise<rfpPolicy[]> => {
    return new Promise<rfpPolicy[]>((resolve, reject)=>{       
        ApiNostromo.post('/rfp/getRfpPolicies', request as any)
        .then((response) => {
            resolve(response.data.rfpPolicies ?? []);
        }).catch(() => {
            reject();
        });


    });

}

const setRfpsExternalId = (sessionID, rfp): Promise<responseBase> => {
    return new Promise<responseBase>((resolve, reject) => {
            const request: Partial<setRfpsRequest> = {
                sessionID: sessionID,
                rfps: [rfp],
                calledFromIntegrationService: false
            };
            ApiNostromo.post('/rfp/setRfpsExternalId', request as any)
                .then((response) => {
                    resolve(response.data);
                })
                .catch(() => {
                    reject();
                });
    });
}

const setRfpsEntities = (sessionID, rfp): Promise<responseBase> => {
    return new Promise<responseBase>((resolve, reject) => {
            const request: Partial<setRfpsRequest> = {
                sessionID: sessionID,
                rfps: [rfp],
                calledFromIntegrationService: false
            };
            ApiNostromo.post('/rfp/SetRFPsEntities', request as any)
                .then((response) => {
                    resolve(response.data);
                })
                .catch(() => {
                    reject();
                });
    });
}


const getIssuanceTunings = (sessionID, priceId): Promise<issuanceCompanyQuestion[]> => {
    return new Promise<issuanceCompanyQuestion[]>((resolve, reject) => {
            const request: Partial<getIssuanceCompanyQuestionsRequest> = {
                sessionID: sessionID,
                priceId:priceId
               
            };
            ApiNostromo.post('/rfp/getIssuanceTunings', request as any)
                .then((response) => {
                    resolve(response.data.questions ?? []);
                })
                .catch(() => {
                    reject();
                });
    });
}


const changeRfpEffectiveDate = (request:changeRfpEffectiveDateRequest) : Promise<changeRfpEffectiveDateResponse> => {
    return new Promise<changeRfpEffectiveDateResponse>((resolve, reject)=>{       
        ApiNostromo.post('/rfp/changeRfpEffectiveDate', request as any)
        .then((response) => {
            resolve(response.data.error ?? false);
        }).catch(() => {
            reject();
        });
    });
}

const updateRFPStatus = (request:changeRFPStatusRequest): Promise<changeRFPStatusResponse> => {
    return new Promise<changeRFPStatusResponse>((resolve, reject) => {
        ApiNostromo.post('/rfp/updateRFPStatus', request as any)
            .then((response) => {
                resolve(response.data);
            }).catch(() => {
                reject();
            });
    });
}


const saveRFPStatus = (request:changeRFPStatusRequest): Promise<changeRFPStatusResponse> => {
    return new Promise<changeRFPStatusResponse>((resolve, reject) => {
        ApiNostromo.post('/rfp/saveRFPStatus', request as any)
            .then((response) => {
                resolve(response.data);
            }).catch(() => {
                reject();
            });
    });
}


const getCustomSupplementvaluesForReceipt = (request:getCustomSupplementvaluesForReceiptRequest): Promise<getCustomSupplementvaluesForReceiptResponse> => {
    return new Promise<getCustomSupplementvaluesForReceiptResponse>((resolve, reject) => {
        ApiNostromo.post('/rfp/getCustomSupplementvaluesForReceipt', request as any)
            .then((response) => {
                resolve(response.data);
            }).catch(() => {
                reject();
            });
    });
}

const getCompanyIssuanceEmails = (sessionId,productId,companyId,productModalityId,whithoutException): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
        const request : getCompanyIssuanceEmailsRequest = {
            sessionID:sessionId,
            companyId:companyId,
            productId:productId,
            productModalityId:productModalityId,
            whithoutException:whithoutException 
        }
        ApiNostromo.post('/rfp/getCompanyIssuanceEmails', request as any)
            .then((response) => {
                resolve(response.data);
            }).catch(() => {
                reject();
            });
    });
}


const getRfpCompanyTunings = (sessionId, rfpId,priceId,companyId,rfpVersion): Promise<getRfpCompanyTuningsResponse> => {
    return new Promise<getRfpCompanyTuningsResponse>((resolve, reject) => {
        const request : getRfpCompanyTuningsRequest = {
            sessionID:sessionId,
            companyId:companyId,
            rfpId:rfpId,
            priceId: priceId,
            rfpVersion:rfpVersion
        }
        ApiNostromo.post('/rfp/getRfpCompanyTunings', request as any)
            .then((response) => {
                resolve(response.data);
            }).catch(() => {
                reject();
            });
    });
}
 
const ajustRfpCompanyPrice = (request:ajustRfpCompanyPriceRequest): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {
        ApiNostromo.post('/rfp/ajustRfpCompanyPrice', request as any)
            .then((response) => {
                resolve(response.data.error);
            }).catch(() => {
                reject();
            });
    });
}

const getManualPricesToRecover = (request:Partial<getRfpCompanyPricesRequest>): Promise<rfpCompanyPrice[]> => {
    return new Promise<rfpCompanyPrice[]>((resolve, reject) => {
        ApiNostromo.post('/rfp/getManualPricesToRecover', request as any)
            .then((response) => {
                resolve(response.data.rfpCompanyPrices ?? []);
            }).catch(() => {
                reject();
            });
    });
}


const setManualPricesToRecover = (request:Partial<setRfpCompanyPricesRequest>): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {
        ApiNostromo.post('/rfp/setManualPricesToRecover', request as any)
            .then((response) => {
                resolve(response.data.error);
            }).catch(() => {
                reject();
            });
    });
}



const getRecoverRfpDocuments = (sessionId, rfpId,rfpVersion): Promise<getRfpDocumentsResponse> => {
    return new Promise<getRfpDocumentsResponse>((resolve, reject) => {
        const request:getRecoverRfpDocumentsRequest = {
            sessionID:sessionId,
            rfpId:rfpId,
            rfpVersion:rfpVersion
        }
        ApiNostromo.post('/rfp/getRecoverRfpDocuments', request as any)
            .then((response) => {
                resolve(response.data);
            }).catch(() => {
                reject();
            });
    });
}

const recoverRfpDocuments =(sessionId, rfpId,rfpVersion,documents): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {
        const request:recoverRfpDocumentsRequest = {
            sessionID:sessionId,
            rfpId:rfpId,
            rfpVersion:rfpVersion,
            companyDocuments:[],
            documents:documents

        }
        ApiNostromo.post('/rfp/recoverRfpDocuments', request as any)
            .then((response) => {
                resolve(response.data.error);
            }).catch(() => {
                reject();
            });
    });

}

const recoverRfpCompanyDocuments =(sessionId, rfpId,rfpVersion,companyDocuments): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {
        const request:recoverRfpDocumentsRequest = {
            sessionID:sessionId,
            rfpId:rfpId,
            rfpVersion:rfpVersion,
            companyDocuments:companyDocuments,
            documents:[]

        }
        ApiNostromo.post('/rfp/recoverRfpCompanyDocuments', request as any)
            .then((response) => {
                resolve(response.data.error);
            }).catch(() => {
                reject();
            });
    });

}

const emailPreview = (request:changeRFPStatusRequest): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
        ApiNostromo.post('/rfp/emailPreview', request as any)
            .then((response) => {
                resolve(response.data ?? '');
            }).catch(() => {
                reject();
            });
    });
}


const emailPreviewPdf = (request:changeRFPStatusRequest): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
        ApiNostromo.post('/rfp/emailPreviewPdf', request as any)
            .then((response) => {
                resolve(response.data ?? '');
            }).catch(() => {
                reject();
            });
    });
}




const emailDraft = (request:Partial<changeRFPStatusRequest>): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {
        ApiNostromo.post('/rfp/emailDraft', request as any)
            .then((response) => {
                resolve(response.data ?? false);
            }).catch(() => {
                reject();
            });
    });
}



const loadInitialdata = (request:Partial<getRfpsInitialDataRequest>): Promise<getRfpsInitialDataResponse> => {
    return new Promise<getRfpsInitialDataResponse>((resolve, reject) => {
        ApiNostromo.post('/rfp/rfpsInitialData', request as any)
            .then((response) => {
                resolve(response.data);
            }).catch(() => {
                reject();
            });
    });
}

const loadRfp0Initialdata = (sessionID,brokerId,productId,rfpId,rfpVersion,userId): Promise<getRfps0InitialDataResponse> => {
    return new Promise<getRfps0InitialDataResponse>((resolve, reject) => {
        
        const request:Partial<getRfps0InitialDataRequest> ={
            sessionID,
            brokerId:brokerId,
            productId:productId,
            rfpId:rfpId,
            rfpVersion:rfpVersion,
            userId:userId
        };

        ApiNostromo.post('/rfp/rfps0InitialData', request as any)
            .then((response) => {
                resolve(response.data);
            }).catch(() => {
                reject();
            });
    });
}

const loadRfp5Initialdata = (sessionID,brokerId,productId,rfpId,rfpVersion,userId): Promise<getRfps5InitialDataResponse> => {
    return new Promise<getRfps5InitialDataResponse>((resolve, reject) => {
        
        const request:Partial<getRfps5InitialDataRequest> ={
            sessionID,
            brokerId:brokerId,
            productId:productId,
            rfpId:rfpId,
            rfpVersion:rfpVersion,
            userId:userId
        };

        ApiNostromo.post('/rfp/rfps5InitialData', request as any)
            .then((response) => {
                resolve(response.data);
            }).catch(() => {
                reject();
            });
    });
}

const loadRfpEntityInitialdata = (sessionID,brokerId,productId,rfpId,rfpVersion,userId,cataloTypes,zipCode,roleId): Promise<getRfpsEntityInitialDataResponse> => {
    return new Promise<getRfpsEntityInitialDataResponse>((resolve, reject) => {
        
        const request:Partial<getRfpsEntityInitialDataRequest> ={
            sessionID,
            catalogTypes:cataloTypes,            
            brokerId:brokerId,
            productId:productId,
            rfpId:rfpId,
            rfpVersion:rfpVersion,
            userId:userId,
            zipCode:zipCode,
            roleId:roleId
        };

        ApiNostromo.post('/rfp/rfpsEntityInitialData', request as any)
            .then((response) => {
                resolve(response.data);
            }).catch(() => {
                reject();
            });
    });
}


const loadRfpComplementaryInitialdata = (sessionID,brokerId,productId,rfpId,rfpVersion,userId,cataloTypes,brokerOptions,companyId,productModalityId,withSuplementData = false,withRfpCompany = false,): Promise<getRfpsComplementaryInitialDataResponse> => {
    return new Promise<getRfpsComplementaryInitialDataResponse>((resolve, reject) => {
        
        const request:Partial<getRfpsComplementaryInitialDataRequest> ={
            sessionID,
            catalogTypes:cataloTypes,            
            brokerId:brokerId,
            productId:productId,
            rfpId:rfpId,
            rfpVersion:rfpVersion,
            userId:userId,
            brokerOptions:brokerOptions,
            companyId: companyId,
            productModalityId:productModalityId,
            withSuplementData:withSuplementData,
            withRfpCompany:withRfpCompany       
        };

        ApiNostromo.post('/rfp/rfpsComplementaryInitialData', request as any)
            .then((response) => {
                resolve(response.data);
            }).catch(() => {
                reject();
            });
    });
}


const getRfpAnswers = (request:Partial<getRfpAnswerRequest>): Promise<getRfpAnswerResponse> => {
    return new Promise<getRfpAnswerResponse>((resolve, reject) => {
        ApiNostromo.post('/rfp/getRfpAnswers', request as any)
            .then((response) => {
                resolve(response.data);
            }).catch(() => {
                reject();
            });
    });
}


const getRfpUsers = (request:Partial<getRfpUsersRequest>): Promise<getRfpUsersResponse> => {
    return new Promise<getRfpUsersResponse>((resolve, reject) => {
        ApiNostromo.post('/rfp/getRfpUsers', request as any)
            .then((response) => {
                resolve(response.data);
            }).catch(() => {
                reject();
            });
    });
}

const setRfpUsers = (request:Partial<setRfpUsersRequest>): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {
        ApiNostromo.post('/rfp/setRfpUsers', request as any)
            .then((response) => {
                resolve(response.data.error);
            }).catch(() => {
                reject();
            });
    });
}

const setRfpCompanyPricesLinkeds = (request:setRfpCompanyPricesLinkedsRequest): Promise<responseBase> => 
{
    return new Promise<responseBase>((resolve, reject) => {
        ApiNostromo.post('/rfp/setRfpCompanyPricesLinkeds', request as any)
            .then((response) => {
                resolve(response.data);
            }).catch(() => {
                reject();
            });
    });
}

const getRFPStatus = (request:getRfpStatusRequest): Promise<rfpStatus[]> => 
{
    return new Promise<rfpStatus[]>((resolve, reject) => {        
        ApiNostromo.post('/rfp/getRFPStatus', request as any)            
            .then((response) => {                                              
                resolve(response.data.rfpStatuses ?? []);
            }).catch(() => {
                reject();
            });
    });
}


const rejectPolicyIssuance = (request:rejectPolicyIssuanceRequest): Promise<responseBase> => {
    return new Promise<responseBase>((resolve, reject) => {
        ApiNostromo.post('/rfp/rejectPolicyIssuance', request as any)
            .then((response) => {
                resolve(response.data);
            }).catch(() => {
                reject();
            });
    });
}

const forcePolicyIssuanceByEmail = (request:forcePolicyIssuanceByEmailRequest): Promise<responseBase> => {
    return new Promise<responseBase>((resolve, reject) => {
        ApiNostromo.post('/rfp/forcePolicyIssuanceByEmail', request as any)
            .then((response) => {
                resolve(response.data);
            }).catch(() => {
                reject();
            });
    });
}
const setRfpCompanyPriceProductCoverage = (sessionId: string, rfpCompanyPriceCoverageRequest: Partial<rfpCompanyPriceCoverage>): Promise<setRfpCompanyPriceCoverageResponse> => {
    const request: setRfpCompanyPriceCoverageRequest = {
        sessionID: sessionId,
        rfpCompanyPriceCoverage: rfpCompanyPriceCoverageRequest,
    }    
    return new Promise<setRfpCompanyPriceCoverageResponse>((resolve, reject) => {        
        ApiNostromo.post('/rfp/setRfpCompanyPriceProductCoverage', request as any)
            .then((response) => {
                resolve(response.data);
            }).catch(() => {
                reject();
            });
    });
}
const reCreatePriceProductCoverage = (sessionId:string, priceId:string) : Promise<rfpCompanyPriceCoverage[]> => {
    return new Promise<rfpCompanyPriceCoverage[]>((resolve,reject)=>{
        const request:getRfpCompanyPriceNotesRequest = {
            sessionID: sessionId,
            priceId: priceId
        };
        ApiNostromo.post('/rfp/reCreatePriceProductCoverage', request as any)
        .then((response) => {
            resolve(response.data.coverages ?? [] as rfpCompanyPriceCoverage[]);            
        })
        .catch(() => {
            reject();
        });
    });
}
const gestionDeCompentencias = (priceId:string,sessionId:string) : Promise<responseBase> => {
    const request: gestionDeCompentenciasRequest = {
        sessionID: sessionId,
        priceId:priceId
    };
    return new  Promise<responseBase>((resolve,reject)=>{
        ApiNostromo.post('/rfp/gestionDeCompentencias', request as any)
        .then((response) => {
            resolve(response.data);
        }).catch(() => {
            reject();
        });
    });
}
const verificarGestionDeCompentencias = (priceId:string,sessionId:string) : Promise<responseBase> => {
    const request: gestionDeCompentenciasRequest = {
        sessionID: sessionId,
        priceId:priceId
    };
    return new  Promise<responseBase>((resolve,reject)=>{
        ApiNostromo.post('/rfp/verificarGestionDeCompentencias', request as any)
        .then((response) => {
            resolve(response.data);
        }).catch(() => {
            reject();
        });
    });
}
const setRfpIssuanceCertification = (sessionId:string,rfpId:string,typeId:string) : Promise<responseBase> => {
    const request:setRfpIssuanceCertificationRequest = {
        sessionID: sessionId,
        rfpId: rfpId,
        typeId:typeId,
    };
    return new Promise<responseBase>((resolve,reject)=>{
        ApiNostromo.post('/rfp/setRfpIssuanceCertification', request as any)
        .then((response) => {
            resolve(response.data);            
        })
        .catch(() => {
            reject();
        });
    });
}
const enviarCorreoNotificaionEmisionPoliza = (rfpId:string,sessionId:string) : Promise<responseBase> =>{
    return new Promise<responseBase>((resolve,reject)=>{
        ApiNostromo.post('/rfp/sendCliendRfpIssuedNotification?rfpId=' + rfpId + "&sessionId=" + sessionId, {})
        .then((response) => {
            resolve(response.data);            
        })
        .catch(() => {
            reject();
        });
    });
}

const recuperarRfpRechazado = (SessionId,rfpId=null): Promise<changeRFPStatusResponse> =>
{
    return new Promise<changeRFPStatusResponse>((resolve,reject)=>{
        const data: Partial<rfp> = {
            id: rfpId
        };
        const request: Partial<changeRFPStatusRequest> = {
            sessionID: SessionId,
            rfp: data,
            newStatus:rfpStatusType.pte_tarificar,
            hibernateCause:null as any,
            date:null,
            prices:null as any,
        };        
        ApiNostromo.post('/rfp/rollbackRFPStatus', request as any)
        .then((response) => {
            resolve(response.data);
        })
        .catch(() => {
            reject();
        });        
    });
}
import { setPriceKeepAliveRequest  } from '../interfaces/nostromo/service/setPriceKeepAliveRequest';
const setPriceKeepAlive = (sessionId:string,priceId:string) : Promise<responseBase> =>{
    return new Promise<responseBase>((resolve,reject)=>{
        const request: Partial<setPriceKeepAliveRequest> = {
            sessionID: sessionId,
            id:priceId,
        };
        ApiNostromo.post('/rfp/setPriceKeepAlive', request as any)
        .then((response) => {
            resolve(response.data);
        })
        .catch(() => {
            reject();
        });
    });
}
const descargarDocumentoServicioCia = (sessionId:string, rfpId:string,companyId:string,documentTypeId:string): Promise<responseBase> => {
    return new Promise<responseBase>((resolve, reject) => {
            ApiNostromo.post('/rfp/downloadMissingRfpCompanyDocument?sessionId=' + sessionId + "&rfpId=" + rfpId + "&companyId=" + companyId + "&documentTypeId=" + documentTypeId, {})
                .then((response) => {
                    resolve(response.data);
                })
                .catch(() => {
                    reject();
                });
    });
}

export default {
    ACTIONS,
    CONSULTAR, 
    RECHAZAR, 
    DETALLES, 
    DOCUMENTOS, 
    DESBLOQUEAR, 
    DUPLICAR, 
    ELIMINAR, 
    RECHAZARMASIVO, 
    lockedActions, 
    DataStatusMap, 
    ObtainDataStatus, 
    rfpActionsEnabled, 
    UnlockRfp, 
    UpdateLockRfps, 
    DeleteRfp, 
    LockRfp, 
    GetRfp, 
    LockRfpDatabase, 
    rollbackRFPStatus,
    UpdateStateRfps,
    checkLock,
    getRfpCompanyPrices,
    SendRfpToBrokerManager,
    SetRfpAdditionalInformation,
    getRfps,
    getRfpDocuments,
    getRfpCompanyPriceNotes,
    massRfpRejection,
    setRfpCompanyPrice,
    setRfpCompanies,
    getRfpCompanyDocuments,
    setRfpDocuments,
    setRfpCompanyDocuments,
    changeRFPStatus,
    setRfpsReference,
    getRfpCompanies,  
    getRfpCompanyLog,  
    confirmRfpCompanyPrice,
    setRfpsObservation,
    getRfpBudgetReport,
    getCoverageComparativeReport,
    checkEditRFPStatus,
    getRfpPolicies,
    setRfpsExternalId,
    setRfpsEntities,
    getIssuanceTunings,
    changeRfpEffectiveDate,
    setRfpContactEmail,
    updateRFPStatus,
    saveRFPStatus,
    getCustomSupplementvaluesForReceipt,
    getGDPR,
    sendGDPRRequest,
    getCompanyIssuanceEmails,
    getRfpCompanyTunings,
    ajustRfpCompanyPrice,
    getManualPricesToRecover,
    setManualPricesToRecover,
    getRecoverRfpDocuments,
    recoverRfpDocuments,
    recoverRfpCompanyDocuments,
    isSolicitud0,
    isSolicitud5,
    isSolicitud6,
    isSolicitud7,
    getChangeRFPStatusRequest,
    emailPreview,
    emailDraft,
    emailPreviewPdf,
    canEdit,
    loadInitialdata,
    loadRfpEntityInitialdata,
    loadRfpComplementaryInitialdata,
    getRfpAnswers,
    getRfpUsers,
    setRfpUsers,
    loadRfp0Initialdata,
    loadRfp5Initialdata,
    setRfpCompanyPricePodium,
    getRfpVersions,
    setRfpNoticeDate,
    setRfpCompanyPricesLinkeds,
    rejectPolicyIssuance,
    forcePolicyIssuanceByEmail,
    getRFPStatus,
    getRfpCurrentStatus,
    setRfpCompanyPriceProductCoverage,
    reCreatePriceProductCoverage,
    gestionDeCompentencias,
    verificarGestionDeCompentencias,
    setRfpIssuanceCertification,
    enviarCorreoNotificaionEmisionPoliza,
    recuperarRfpRechazado, 
    setPriceKeepAlive,   
    descargarDocumentoServicioCia,
}
